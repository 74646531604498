import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import * as SmoothScroll from 'smooth-scroll';
import { ViewportService } from 'src/app/services/viewport.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  isFixed: boolean;
  collapsed = true;
  inViewport: string;
  sticky: number;

  constructor(public viewportService: ViewportService) {}

  ngOnInit() {
    this.sticky = document.getElementById('navbar').offsetTop;
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck(): void {
    this.inViewport = this.viewportService.isInViewport;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 150) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }
}
